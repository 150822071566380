<template>
  <div>
    <div
      style="width: fit-content; height: fit-content"
      @click="sectVisible = true"
    >
      <slot></slot>
    </div>

    <el-dialog
      :title="'门派'"
      :visible.sync="sectVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      @closed="closed"
      width="400px"
    >
      <el-form ref="sectForm" :model="sectParams" label-width="0">
        <el-form-item label="" prop="search_id">
          <div style="display: flex; flex-direction: row">
            <el-input
              v-model="sectParams.search_id"
              style="width: 300px"
              placeholder="输入6位门派ID后点击回车列出门派信息"
              maxlength="6"
              @change="searchHandler"
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-button
              v-if="sectParams.search_id.length == 6"
              icon="el-icon-search"
              circle
              style="margin-left: 10px"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="" prop="sect_name" style="height: 90px">
          <div
            v-show="sectParams.sect.sect_id && sectParams.sect.sect_id.length"
            style="display: flex; flex-direction: row; border: 1px solid #aaaaaa; border-radius: 5px; padding: 10px; box-sizing: border-box; align-items: center"
          >
            <div
              style="display: flex; align-items: center; justify-content: center"
            >
              <img
                :src="sectParams.sect.sect_avatar"
                style="width: 40px; height: 40px; border-radius: 20px"
              />
            </div>
            <div style="flex: fit-content; padding: 0 20px">
              <div>
                {{ this.sectParams.sect.sect_id }}
              </div>
              <div>
                {{ this.sectParams.sect.sect_name }}
              </div>
            </div>
            <div>
              <el-button type="primary" icon="el-icon-check" @click="select"
                >选择</el-button
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "sectSelect",
  emits: ["onSelect"],
  data() {
    return {
      type: true,
      loading: false,
      sectVisible: false,
      sectParams: {
        search_id: "",
        sect: {
          sect_id: "",
          sect_name: "",
          sect_avatar: "",
          members: [],
        },
      },
    };
  },
  watch: {
    $route: {
      handler: function(val) {
        this.type = val.query.type_id;
      },
      immediate: true,
    },
  },
  methods: {
    async searchHandler() {
      if (this.sectParams.search_id.length == 6) {
        this.loading = true;
        if (this.$route.query.match_type.includes('wpl')) {
          this.sectParams.is_audition = true;
        } else {
          this.sectParams.is_audition = false;
        }

        this.sectParams.is_bind = false;
        let { data, errorCode } = await this.$http.match.getSectMembers(
          this.sectParams.search_id,
          this.sectParams.is_bind,
          this.sectParams.is_audition
        );
        this.loading = false;
        if (errorCode != "0000") {
          return;
        }

        if (data) {
          this.sectParams.sect = data;
        } else {
          this.$message.warning("未查找到门派信息");
          this.sectParams.sect = {
            sect_id: "",
            sect_name: "",
            sect_avatar: "",
            members: [],
          };
        }
      }
    },
    select() {
      this.$emit("onSelect", this.sectParams.sect);
      this.sectVisible = false;
      this.sectParams = {
        search_id: "",
        sect: {
          sect_id: "",
          sect_name: "",
          sect_avatar: "",
          members: [],
        },
      };
    },
    closed() {
      this.sectParams = {
        search_id: "",
        sect: {
          sect_id: "",
          sect_name: "",
          sect_avatar: "",
          members: [],
        },
      };
    },
  },
};
</script>

<style scoped>
/deep/ .el-dialog__body {
  padding: 0 20px 30px 20px;
}
</style>
